/* You can add global styles to this file, and also import other style files */
/*!
 * Start Bootstrap - SB Admin Bootstrap Admin Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

 @import url(https://fonts.googleapis.com/css?family=Open+Sans);
 @font-face {
    font-family: guageFont;
    src: url('~/assets/fonts/MS33558.ttf');
  }
  @font-face {
    font-family: aa-reg-font-reg;
    src: url('~/assets/fonts/americansans-regular.woff');
  }  
  @font-face {
    font-family: aa-reg-font-med;
    src: url('~/assets/fonts/americansans-medium.woff');
  }    

 /* Global Styles */
 
 body {
     /* margin-top: 60px; */
     background-color:#eeeeee;
     font-family: 'Open Sans', sans-serif;
     font-size: 1em;
 }

 .aa-line {
  margin: 15px 0;
  margin-bottom: 15px;
  height: 2px;
  border: 0;
  background: #2989d8;
  background: -moz-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #2989d8), color-stop(31%, #a8a8a8), color-stop(53%, #e3e3e3), color-stop(80%, #e73827), color-stop(100%, #ed042b));
  background: -webkit-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: -o-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: -ms-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: linear-gradient(to right, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#2989d8', endColorstr='#ed042b', GradientType=1);
} 

.aa-status-line {
  border: 0;
  background: #2989d8;
  background: -moz-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #2989d8), color-stop(31%, #a8a8a8), color-stop(53%, #e3e3e3), color-stop(80%, #e73827), color-stop(100%, #ed042b));
  background: -webkit-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: -o-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: -ms-linear-gradient(left, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  background: linear-gradient(to right, #2989d8 0%, #a8a8a8 31%, #e3e3e3 53%, #e73827 80%, #ed042b 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#2989d8', endColorstr='#ed042b', GradientType=1);
} 

.sexy-line {
  margin: 5px 0;
  margin-bottom: 20px;
  display: block;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(#000), to(#fff));
}

.sexy-narrow-line {
  margin: 2px 0;
  margin-bottom: 2px;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 150, from(#000), to(#fff));
}

label {
  font-weight: bold;
}

.bg-ava-danger {
  background-color: #f2dede;
  color: #a94442;
  border-color: #ebccd1;
  font-size: 16px;
}

.bg-ava-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  font-size: 16px;
}
 
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid red;
  border-color: #ed042b transparent #2989d8 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

h4 {
  font-size: 1.2em;
  color: #2989d8;
  text-decoration: underline;
}

.panel-american-blue {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2989d8+0,0010f7+43,0010f7+43,3324b7+77 */
  background: #2989d8;
  /* Old browsers */
  opacity: .75;
  background: -moz-linear-gradient(left, #2989d8 1%, #367dc4 26%, #0019bf 87%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(1%, #2989d8), color-stop(26%, #367dc4), color-stop(87%, #0019bf));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #2989d8 1%, #367dc4 26%, #0019bf 87%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #2989d8 1%, #367dc4 26%, #0019bf 87%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #2989d8 1%, #367dc4 26%, #0019bf 87%);
  /* IE10+ */
  background: linear-gradient(to right, #2989d8 1%, #367dc4 26%, #0019bf 87%);
  /* W3C */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#2989d8', endColorstr='#0019bf', GradientType=1);
  /* IE6-9 */
  color: whitesmoke;
}

.panel-american-grey-blue {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a8a8a8+1,367dc4+90,367dc4+100 */
  background: #a8a8a8;
  /* Old browsers */
  background: -moz-linear-gradient(left, #a8a8a8 1%, #367dc4 90%, #367dc4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(1%, #a8a8a8), color-stop(90%, #367dc4), color-stop(100%, #367dc4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #a8a8a8 1%, #367dc4 90%, #367dc4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #a8a8a8 1%, #367dc4 90%, #367dc4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #a8a8a8 1%, #367dc4 90%, #367dc4 100%);
  /* IE10+ */
  background: linear-gradient(to right, #a8a8a8 1%, #367dc4 90%, #367dc4 100%);
  /* W3C */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#a8a8a8', endColorstr='#367dc4', GradientType=1);
  /* IE6-9 */
  color: white;
}

.panel-american-red {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ed042b+0,8f0222+65,6d0019+100 */
  background: #ed042b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #ed042b 0%, #8f0222 65%, #6d0019 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #ed042b), color-stop(65%, #8f0222), color-stop(100%, #6d0019));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #ed042b 0%, #8f0222 65%, #6d0019 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #ed042b 0%, #8f0222 65%, #6d0019 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #ed042b 0%, #8f0222 65%, #6d0019 100%);
  /* IE10+ */
  background: linear-gradient(to right, #ed042b 0%, #8f0222 65%, #6d0019 100%);
  /* W3C */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ed042b', endColorstr='#6d0019', GradientType=1);
  /* IE6-9 */
  color: whitesmoke;
}

.panel-american-coral {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ed042b+0,8f0222+65,6d0019+100 */
  background: #ed042b;
  /* Old browsers */
  background: -moz-linear-gradient(left, coral 0%, #8f0222 65%, #6d0019 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, coral), color-stop(65%, #8f0222), color-stop(100%, #6d0019));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, coral 0%, #8f0222 65%, #6d0019 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, coral 0%, #8f0222 65%, #6d0019 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, coral 0%, #8f0222 65%, #6d0019 100%);
  /* IE10+ */
  background: linear-gradient(to right, coral 0%, #8f0222 65%, #6d0019 100%);
  /* W3C */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='coral', endColorstr='#6d0019', GradientType=1);
  /* IE6-9 */
  color: whitesmoke;
}

.panel-american-blue-grey-red {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ed042b+0,8f0222+65,6d0019+100 */
  background: #ed042b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #367dc4 0%, #a8a8a8 50%, #6d0019 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #367dc4), color-stop(50%, #a8a8a8), color-stop(100%, #6d0019));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #367dc4 0%, #a8a8a8 50%, #6d0019 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #367dc4 0%, #a8a8a8 50%, #6d0019 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #367dc4 0%, #a8a8a8 50%, #6d0019 100%);
  /* IE10+ */
  background: linear-gradient(to right, #367dc4 0%, #a8a8a8 50%, #6d0019 100%);
  /* W3C */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#367dc4', endColorstr='#6d0019', GradientType=3);
  /* IE6-9 */
  color: whitesmoke;
}

.cursor-pointer {
  cursor: pointer;
}

.cat-green {
  color : green;
}
.cat-red {
  color : red;
}
.to-top {
  bottom: 0;
  cursor: pointer;
  margin: 0 8px 12px 0;
  position: fixed;
  right: 0;
  opacity: 0;
}

.goToTop {
  position: fixed;
  font-size: 3em;
  bottom:0;
  right:0;
  margin-bottom: 20px;
  margin-right: 20px;
  z-index: 100000;
  cursor: pointer;
  color : #23527c;
}
.show-scrollTop {
  opacity: 1 !important;
  transition: all 0.2s ease-in-out;
}

.leftnavRow {
  margin-top: 10px;
  margin-bottom: 20px;
}

.leftnavTitle {
  font-size: 1.25em;
  color: cornflowerblue;
}

.leftnavText {
  margin-left: 0px;
}

.pointer {
  cursor: pointer;
}